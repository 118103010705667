import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
// import 'video.js/dist/video.js'
import "./assets/css/index.scss"
import "./assets/css/index-C54-nLyP.css"
import "./assets/css/index-JNO-gHs4.css"

createApp(App).use(store).use(router).use(ElementPlus).use(VideoPlayer).mount("#app");
