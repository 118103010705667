<template>
  <div class="order">
    <div class="header">
      <img :src="noticeInfo.cover" alt="" class="avatar">
      <div class="info">
        <div class="number">订单号：{{ oid }}</div>
        <div class="desc">{{ noticeInfo.content }}{{ noticeInfo.contact }}</div>
      </div>
    </div>
    <div class="tips">{{ noticeInfo.tip }}{{ noticeInfo.contact }}</div>
    <div>
      <ul class="list" v-if="checkOrder(oid)">
        <li v-for="item in videoList" class="item" @click="toDetailPage(item)">
          <div class="item-content">
            <img :src="item.coverUrl" alt="" class="cover">
            <div class="item-title ov" v-if="parseInt(item.chapter)<1">{{ item.title }}</div>
            <div class="item-title ov" v-if="item.chapter>0">第{{ item.chapter }}节.{{ item.title }}</div>
          </div>
        </li>
      </ul>
    </div>
    <!--    <div style="text-align: center;position: fixed;bottom: 0;left:0;width:100%;display: inline-block"><span>粤ICP备2024306182号-2</span></div>-->
    <div class="icp">
      <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2024306182号-2</a>
    </div>
  </div>
</template>
<script setup>
import router from '@/router/index.js'
import {onMounted, ref} from 'vue'
import {getLesson, getNotice} from '@/api/lessonService';
import {useRoute} from "vue-router/dist/vue-router";

const videoList = ref(null)
const noticeInfo = ref({})
const route = useRoute();
const oid = ref('');

onMounted(() => {

  console.log("valid=", oid.value)
  const urlParams = new URLSearchParams(window.location.search);
  let orderNo = urlParams.get('oid');

  console.log("orderNo",orderNo)
  let valid = checkOrder(orderNo);
  if (valid) {
    oid.value = orderNo
    console.log('OID:', oid.value);
    getLessonList();
  }
  getNoticeInfo();
});

function checkOrder(orderNumber) {
  // 正则表达式，匹配以6开头的19位数字
  var regex = /^6\d{18}$/;
  return regex.test(orderNumber);
}

async function getLessonList() {
  try {
    const response = await getLesson();
    console.log('response~~~~~', response)
    videoList.value = response.data.list;
    console.log('videoList~~~~~', videoList)
  } catch (error) {
    console.error('Failed to fetch user info:', error);
  } finally {
    // loading.value = false;
  }
}

async function getNoticeInfo() {
  try {
    const responseNotice = await getNotice();
    console.log('response~~~~~', responseNotice)
    noticeInfo.value = responseNotice.data;
    console.log('videoList~~~~~', noticeInfo)
  } catch (error) {
    console.error('Failed to fetch user info:', error);
  } finally {
    // loading.value = false;
  }
}

//视频播放页面
function toDetailPage(item) {
  if (item.chapter < 0) {
    addWx(item)
  } else {
    router.push({
      path: '/about',
      query: {
        id: item.chapter
      }
    })
  }
}

function addWx(item) {
  window.location.href = item.videoUrl
}
</script>
<style lang="scss" scoped>
.order{
  padding: 10px 20px;
}
.header {
  display: flex;

  .avatar {
    width: 100px;
    height: 100px;
    background: #5f9ea0;
    border-radius: 6px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .number {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
  }

  .desc {
    font-size: 16px;
  }
}

.tips {
  padding: 10px 0;
  font-size: 14px;
  color: #00f;
  border-top: 1px dashed #dddddd;
  border-bottom: 1px dashed #dddddd;
  margin-top: 10px;
}

.list {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  margin-top: 10px;
  margin-bottom: 20px;

  .item {
    box-shadow: 0 4px 30px #0050b314;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    display: inline-block;

    .item-content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .cover {
      width: 100%;
      aspect-ratio: 16 / 9;
      background: #999;
      object-fit: cover;
    }

    .item-title {
      padding: 10px;
      font-size: 16px;
    }
  }
}

.icp {
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
  color: #999;

  div {
    display: block;
    unicode-bidi: isolate;

    a {
      text-decoration: none;
    }
  }
}
</style>
